import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../context/AuthProvider";
import { HttpHeadersContext } from "../context/HttpHeadersProvider";

const QuestionFourth = () => {
  const { auth, setAuth } = useContext(AuthContext);
  const { headers, setHeaders } = useContext(HttpHeadersContext);

  const [prompt, setPrompt] = useState("");
  const [answer, setAnswer] = useState("");
  const [utterance, setUtterance] = useState("");
  const [loading, setLoading] = useState(false);

  const [question, setQuestion] = useState({
    id: 5,
    score: 0,
    isCompleted: false,
  });

  const client = axios.create({ baseURL: `https://game-api.haejun.info` });

  useEffect(() => {
    // 컴포넌트 마운트 시에 실행될 함수
    const fetchQuestion = async () => {
      try {
        const response = await client.get("/questions/5", {
          headers: headers,
        });

        setQuestion(response.data);
      } catch (error) {
        console.error("문제를 가져오는 데 실패했습니다.", error);
      }
    };

    fetchQuestion();
  }, []);

  console.log(question);

  const changePrompt = (event) => {
    setPrompt(event.target.value);
  };

  const changeAnswer = (event) => {
    setAnswer(event.target.value);
  };

  const inference = async () => {
    console.log(prompt);

    const req = {
      number: 5,
      prompt: prompt,
    };
    setLoading(true);

    await client
      .post("/questions/inference", req, { headers: headers })
      .then((resp) => {
        setUtterance(resp.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false); // 로딩 상태 종료
      });
  };

  const subject = async () => {
    console.log(answer);

    const req = {
      questionId: 5,
      answer: answer,
    };

    await client
      .post("/questions/subject", req, {
        headers: headers,
      })
      .then((resp) => {
        console.log(resp.data);

        if (resp.data.success == true) {
          alert("정답입니다!");
          window.location.reload();
        } else {
          alert("오답입니다!");
        }
      });
  };

  return (
    <div className="container mt-5">
      <div className="card" style={{ maxWidth: "600px", margin: "0 auto" }}>
        {/* <img
          src="your-image-url.jpg" // 이미지 URL을 여기에 입력하세요.
          alt="Wizard"
          className="card-img-top"
        /> */}
        {question.isCompleted === true ? (
          <img width="64" height="64" src="/images/marker.png" />
        ) : (
          ""
        )}
        <div className="card-body">
          <h5 className="card-title text-center">(LVL 5)</h5>
          <p className="card-text text-center">
            ㅁ?ㄹ
            <br /> 이번 모델은 ㅇㅇ ㅁ?ㄹ ㄴㄴ 밖에 할줄 모른다.
            <br /> 거기다 모델의 대답은 진짜인지 거짓인지도 모른다!
            <br />
            <br /> 다양한 질문을 통해 AI로부터 [password]를 알아내보자!
            <br />
            <br />
            [사람]: 몇 글자인가요?
            <br />
            [AI]: ㅁ?ㄹ
            <br />
            <br />
            [사람]: 20글자 미만인가요?
            <br />
            [AI]: ㅇㅇ
            <br />
            <br />
            [사람]: [prompt]
            <br />
            [AI]:
            <br />
          </p>

          <div className="input-group mb-3">
            <textarea
              className="form-control"
              placeholder="[prompt]"
              rows="3"
              onChange={changePrompt}
            ></textarea>
            <div className="input-group-append">
              <button className="btn btn-primary" onClick={inference}>
                추론
              </button>
            </div>
          </div>
          <div class="text-center">
            {loading ? <div class="loading_circle"></div> : <p>{utterance}</p>}
          </div>
          <div className="d-flex justify-content-between mb-3">
            <input
              type="text"
              className="form-control mr-2"
              style={{ flex: 1 }}
              placeholder="답변을 입력하세요..."
              onChange={changeAnswer}
            />
            <button className="btn btn-success" onClick={subject}>
              제출하기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionFourth;
