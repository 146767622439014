import { Routes, Route } from "react-router-dom";

import Home from "../app/Home";
import Leaderboard from "../leaderboard/Leaderboard";
import Join from "../member/Join";
import Login from "../member/Login";
import Logout from "../member/Logout";
import QuestionFirst from "../question/QuestionFirst";
import QuestionSecond from "../question/QuestionSecond";
import QuestionThird from "../question/QuestionThird";
import QuestionFourth from "../question/QuestionFourth";
import QuestionFifth from "../question/QuestionFifth";

function Router() {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>

      <Route path="/question1" element={<QuestionFirst />}></Route>
      <Route path="/question2" element={<QuestionSecond />}></Route>
      <Route path="/question3" element={<QuestionThird />}></Route>
      <Route path="/question4" element={<QuestionFourth />}></Route>
      <Route path="/question5" element={<QuestionFifth />}></Route>

      <Route path="/login" element={<Login />}></Route>
      <Route path="/join" element={<Join />}></Route>
      <Route path="/logout" element={<Logout />}></Route>
      <Route path="/leaderboard" element={<Leaderboard />}></Route>
    </Routes>
  );
}

export default Router;
