/* 회원가입 컴포넌트 */

import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router";

function Join() {
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [pwd, setPwd] = useState("");
  const [checkPwd, setCheckPwd] = useState("");

  const navigate = useNavigate();

  const client = axios.create({ baseURL: `https://game-api.haejun.info` });

  const changeId = (event) => {
    setId(event.target.value);
  };

  const changeName = (event) => {
    setName(event.target.value);
  };

  const changePwd = (event) => {
    setPwd(event.target.value);
  };

  const changeCheckPwd = (event) => {
    setCheckPwd(event.target.value);
  };

  /* 회원가입 */
  const join = async () => {
    const req = {
      id: id,
      name: name,
      pwd: pwd,
    };

    await client
      .post("/accounts", req)
      .then((resp) => {
        console.log("[Join.js] join() success :D");
        console.log(resp.data);

        alert(resp.data.name + "님 회원가입을 축하드립니다 🎊");
        navigate("/login");
      })
      .catch((err) => {
        console.log("[Join.js] join() error :<");
        console.log(err);

        const resp = err.response;
        if (resp.status == 400) {
          alert(resp.data);
        }
      });
  };

  return (
    <div>
      <table className="table">
        <tbody>
          <tr>
            <th className="col-2">아이디</th>
            <td>
              <input type="text" value={id} onChange={changeId} size="50px" />{" "}
              &nbsp; &nbsp;
            </td>
          </tr>

          <tr>
            <th>이름</th>
            <td>
              <input
                type="text"
                value={name}
                onChange={changeName}
                size="50px"
              />
            </td>
          </tr>

          <tr>
            <th>비밀번호</th>
            <td>
              <input
                type="password"
                value={pwd}
                onChange={changePwd}
                size="50px"
              />
            </td>
          </tr>

          <tr>
            <th>비밀번호 확인</th>
            <td>
              <input
                type="password"
                value={checkPwd}
                onChange={changeCheckPwd}
                size="50px"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <br />

      <div className="my-3 d-flex justify-content-center">
        <button className="btn btn-outline-secondary" onClick={join}>
          <i className="fas fa-user-plus"></i> 회원가입
        </button>
      </div>
    </div>
  );
}

export default Join;
