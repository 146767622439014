import React, { useEffect, useState } from "react";
import "./Leaderboard.css";

const Leaderboard = () => {
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    // Fetch the rankings from the API when the component mounts
    const fetchRankings = async () => {
      try {
        const response = await fetch(
          "https://game-api.haejun.info/accounts/rank"
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setPlayers(data);
      } catch (error) {
        console.error("Could not fetch the data", error);
      }
    };

    fetchRankings();
  }, []); // The empty array as a second argument ensures the effect runs once on mount

  return (
    <div className="leaderboard">
      <h2>LEADERBOARD</h2>
      <ul>
        {players
          .sort((a, b) => b.score - a.score)
          .map((player, index) => (
            <li
              key={player.id}
              className={`player ${
                index === 0
                  ? "first"
                  : index === 1
                  ? "second"
                  : index === 2
                  ? "third"
                  : ""
              }`}
            >
              {index === 0 ? (
                "🥇"
              ) : index === 1 ? (
                "🥈"
              ) : index === 2 ? (
                "🥉"
              ) : (
                <span className="rank">{index + 1}</span>
              )}

              <span className="name">{player.name}</span>
              <span className="score">{player.score}</span>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Leaderboard;
